import React, { Suspense, useEffect, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate  } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Loading } from "./loading";

const queryClient = new QueryClient();

// Lazy-loaded components
const Pricing = lazy(() => import("./pricing"));
const Register = lazy(() => import("./register"));
const Login = lazy(() => import("./login"));
const Forgot = lazy(() => import("./forgot"));
// const Reset = lazy(() => import("./resetpass"));
const Landing = lazy(() => import("./lp/landing"));
const Pay = lazy(() => import("./pay"));
const PayV2 = lazy(() => import("./pay/index_02"));
const PayV3 = lazy(() => import("./pay/index_03"));
const Verify = lazy(() => import("./verify/index"));
const EmailVerification = lazy(() => import("./verify/index_01"));

function App() {
  useEffect(() => {
    import("./tailwind.scss");
    import("./index.css");
  }, []);

  return (
    <Router>
      <div>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Landing />} /> 
              <Route path="/landing" element={<Landing />} />
              <Route path="/pricing" element={<Navigate to="/landing/plan" />} />
              <Route path="/register" element={<Navigate to="/landing/register" />} />
              <Route path="/pay/1LCXJMZNX6" element={<Pay />} />
              <Route path="/pay/VS6lni4hKx" element={<PayV2 />} />
              <Route path="/pay/uHTinVqsUl" element={<PayV3 />} />
              <Route path="/login" element={<Navigate to="/landing/login" />} />
              <Route path="/forgot" element={<Navigate to="/landing/forgot-password" />} />

              <Route path="/landing/register" element={<Register />} />
              <Route path="/landing/login" element={<Login />} />
              <Route path="/landing/forgot-password" element={<Forgot />} />
              <Route path="/landing/plan" element={<Pricing />} />
              <Route path="/landing/verify" element={<Verify />} />
              <Route path="/landing/verifying-email" element={<EmailVerification />} />
              <Route path="/landing/pay/1LCXJMZNX6" element={<Pay />} />
              <Route path="/landing/pay/VS6lni4hKx" element={<PayV2 />} />
              <Route path="/landing/pay/uHTinVqsUl" element={<PayV3 />} />
              {/* {/* <Route path="/resetpass" element={<Reset />} /> */}
            </Routes>
          </Suspense>
        </QueryClientProvider>
        <div className="loader-container">
          <div className="overlay"></div>
          <div className="lds-ai">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </Router>
  );
}
export default App;
